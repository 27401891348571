export default function Maps() {
    return (
        <>
            <iframe 
                title="Maps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2298.948473688615!2d17.953179175976075!3d54.81602136375395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fdcd844efc67cb%3A0xb0e65670d9757061!2sPokoje%20go%C5%9Bcinne%20ARKA!5e0!3m2!1sen!2spl!4v1698231543940!5m2!1sen!2spl" 
                width="600" 
                height="450" 
                allowfullscreen="" 
                loading="lazy" 
                referrerpolicy="no-referrer-when-downgrade">
            </iframe>
        </>
    )
}