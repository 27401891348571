import House from './House';
import Room from './Room';
import Studio from './Studio';
import Cabin from './Cabin';
import Bialogora from './Bialogora';

export default function MobileGallery() {
    
      return (
        <div className='galleryMobile'>
          <House/>
          <br/>
          <Room/>
          <br/>
          <Studio/>
          <br/>
          <Cabin/>
          <br/>
          <Bialogora/>
        </div>
      );
}