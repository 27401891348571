import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

export default function Bialogora() {
 const images = [
    {
      original: require("../../images/okolica.jpg"),
      thumbnail: require("../../images/okolica.jpg"),
      description: "Jedno z wejść na plażę"
    },
    {
        original: require("../../images/plaza.jpg"),
        thumbnail: require("../../images/plaza.jpg"),
        description: "Urokliwa plaża przy wejściu 36"
      },
      {
        original: require("../../images/okolica2.jpg"),
        thumbnail: require("../../images/okolica2.jpg"),
        description: "wejście nr 36"
      },
      {
        original: require("../../images/okolica3.jpg"),
        thumbnail: require("../../images/okolica3.jpg"),
        description: "zachód słońca nad morzem"
      },
      {
        original: require("../../images/okolica6.jpg"),
        thumbnail: require("../../images/okolica6.jpg"),
        description: "plaża główna wieczorem"
      },
      {
        original: require("../../images/okolica4.jpg"),
        thumbnail: require("../../images/okolica4.jpg"),
        description: "główna plaża - taras widokowy"
      },
      {
        original: require("../../images/okolica5.jpg"),
        thumbnail: require("../../images/okolica5.jpg"),
        description: "na trasie wycieczkowej przy wydmach"
      },
      {
        original: require("../../images/okolica7.jpg"),
        thumbnail: require("../../images/okolica7.jpg"),
        description: "widok na wydmy"
      }
  ];

  return (
    <ImageGallery items={images} />
  );
}