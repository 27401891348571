import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

export default function Cabin() {
 const images = [
    {
      original: require("../../images/letniskowy.jpg"),
      thumbnail: require("../../images/letniskowy.jpg"),
      description: "Domek z zewnątrz"
    },
    {
        original: require("../../images/letniskowy1.jpg"),
        thumbnail: require("../../images/letniskowy1.jpg"),
        description: "Domek letniskowy"
      },
      {
        original: require("../../images/letniskowy2.jpg"),
        thumbnail: require("../../images/letniskowy2.jpg"),
        description: "Salon"
      },
      {
        original: require("../../images/letniskowy3.jpg"),
        thumbnail: require("../../images/letniskowy3.jpg"),
        description: "Aneks kuchenny"
      },
      {
        original: require("../../images/letniskowy4.jpg"),
        thumbnail: require("../../images/letniskowy4.jpg"),
        description: "Łazienka"
      },
      {
        original: require("../../images/letniskowy6.jpg"),
        thumbnail: require("../../images/letniskowy6.jpg"),
        description: "Domek z przodu"
      }
  ];

  return (
    <ImageGallery items={images} />
  );
}