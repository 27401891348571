import ImageGallery from "react-image-gallery";
// import stylesheet if you're not already using CSS @import
import "react-image-gallery/styles/css/image-gallery.css";

export default function House() {
 const images = [
    {
      original: require("../../images/osrodek.jpg"),
      thumbnail: require("../../images/osrodek.jpg"),
      description: "Budynek z przodu"
    },
    {
        original: require("../../images/domek.jpg"),
        thumbnail: require("../../images/domek.jpg"),
        description: "Budynek z tyłu"
      },
      {
        original: require("../../images/domek1.jpg"),
        thumbnail: require("../../images/domek1.jpg"),
        description: "Budynek"
      },
      {
        original: require("../../images/domek2.jpg"),
        thumbnail: require("../../images/domek2.jpg"),
        description: "Taras z przodu"
      },
      {
        original: require("../../images/domek3.jpg"),
        thumbnail: require("../../images/domek3.jpg"),
        description: "Ogród"
      },
      {
        original: require("../../images/domek4.jpg"),
        thumbnail: require("../../images/domek4.jpg"),
        description: "Ogród z altaną"
      },
      {
        original: require("../../images/domek5.jpg"),
        thumbnail: require("../../images/domek5.jpg"),
        description: "Altana"
      },
      {
        original: require("../../images/domek6.jpg"),
        thumbnail: require("../../images/domek6.jpg"),
        description: "Widok na ogród"
      }
  ];

  return (
    <ImageGallery items={images} />
  );
}