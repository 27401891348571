import { Link } from "react-router-dom";

export default function Footer() {
    let mybutton = document.getElementById("myBtn");
    window.onscroll = function() {scrollFunction()};

function scrollFunction() {
    let mybutton = {};
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    let mybutton = {display:"block"};
  } else {
    let mybutton = {display:"block"};
  }
  return mybutton;
}

// When the user clicks on the button, scroll to the top of the document
function topFunction() {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}
    return (
<footer className="footer">
  	 <div className="footer__container">
  	 	<div className="footer__row">
  	 		<div className="footer__col">
  	 			<h4>"Arka"</h4>
                <div>
  	 				<span>Grażyna i Jerzy<br/>Zakrzewscy</span><br/><br/>
                    <span>ul. Lubiatowska 2c</span><br/>
                    <span>84-113, Białogóra</span>
  	 			</div>
  	 		</div>
  	 		<div className="footer__col">
  	 			<h4>Kontakt</h4>
  	 			<div>
                    <span className="footer__contact">telefon:<a href="tel:+48-698-890-790">+48 698-890-790</a></span><br/>
                    <span className="footer__contact">e-mail:<a href="mailto:biuro@bialogora-arka.pl">biuro@bialogora-arka.pl</a></span>
  	 			</div>
  	 		</div>
  	 		<div className="footer__col">
  	 			<h4>Menu</h4>
  	 			<ul>
  	 				<li><Link to="/" className="nav__link" onClick={topFunction} style={mybutton}>Strona Główna</Link></li>
  	 				<li><Link to="/pokoje" className="nav__link" onClick={topFunction} style={mybutton}>Galeria</Link></li>
  	 				<li><Link to="/cennik" className="nav__link" onClick={topFunction} style={mybutton}>cennik</Link></li>
                    <li><Link to="/kontakt" className="nav__link" onClick={topFunction} style={mybutton}>kontakt</Link></li>
  	 				<li><Link to="/ciekawostki" className="nav__link" onClick={topFunction} style={mybutton}>ciekawostki</Link></li>
  	 			</ul>
  	 		</div>
  	 		<div className="footer__col">
  	 			<div className="social-links">
                   <a 
                        href="https://www.facebook.com/profile.php?id=100010020481762" 
                        target="_blank"
                        rel="noreferrer">
                        <img alt="Facebook" src={require("../../images/facebook.png")}></img>
                    </a>
                    <a 
                        href="https://maps.app.goo.gl/AkCoGu9iKGRzjrDL8" 
                        target="_blank"
                        rel="noreferrer">
                        <img alt="Maps" src={require("../../images/maps.png")}></img>
                    </a>
  	 			</div>
  	 		</div>
  	 	</div>
  	 </div>
     <div className="copyright">
      <span>&copy;Copyright 2024 <a href="https://www.bialogora-arka.pl/" target="blank" >bialogora-arka.pl</a><br/>Projekt i wykonanie Karolina Nalaskowska</span>
     </div>
  </footer>
    )
}