import Footer from "./components/Footer";
import Header from "./components/Header";
import Maps from "./components/Maps";

export default function Contact() {
    return (
        <>
        <Header/>
            <section className="contact">
                <h2 className="contact__title">Kontakt</h2>
                <div className="contact__container">
                    <div className="contact__left">
                        <div className="contact__text contact__center">
                        Grażyna i Jerzy Zakrzewscy<br/>
                        ul. Lubiatowska 2c<br/>
                        84-113, Białogóra
                        </div>
                        <div className="contact__text">
                        Telefon:<br/><a href="tel:+48-698-890-790">+48 698-890-790</a><br/><br/>
                        E-mail:<br/> <a href="mailto:biuro@bialogora-arka.pl">biuro@bialogora-arka.pl</a>
                        <br/><br/>
                        <div className="contact__icons">
                        <a 
                            href="https://www.facebook.com/profile.php?id=100010020481762" 
                            target="_blank"
                            rel="noreferrer"
                            className="contact__facebook">
                            <img alt="Facebook" src={require("../images/facebook.png")}></img>Facebook
                        </a>
                        <a 
                            href="https://maps.app.goo.gl/AkCoGu9iKGRzjrDL8" 
                            target="_blank"
                            rel="noreferrer"
                            className="mobileMap">     
                            <img alt="Maps" src={require("../images/maps.png")}></img>Jak dojechać
                        </a>
                        </div>
                        </div>
                    </div>
                    <div className="contact__right">
                        <Maps/>
                    </div>
                </div>
            </section>
        <Footer/>
        </>
    )
}