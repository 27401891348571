import Footer from "./components/Footer";
import Header from "./components/Header";
import React from 'react';
import Popup from 'reactjs-popup';
import House from "./components/House";
import Room from "./components/Room";
import Cabin from "./components/Cabin";
import Studio from "./components/Studio";
import Bialogora from "./components/Bialogora";
import MobileGallery from "./components/MobileGallery";

export default function Rooms() {
    return (
        <>
            <Header/>
            <section className="gallery">
                <h2 className="gallery__title">Galeria zdjęć</h2>
                <div className="gallery__container">
                <div className="mobileGallery">
                    <MobileGallery/>
                </div>
                    <div className="wrapper">
                    <div className="gallery__single">
                        <Popup 
                            trigger=
                            {<img
                                className="gallery__photo" 
                                alt="Budynek" 
                                src={require("../images/domek1.jpg")}
                                ></img>} 
                                position="absolute">
                            <House/>
                        </Popup>
                    </div>
                    <span>Budynek</span>
                    </div>
                    
                    <div className="wrapper">
                    <div className="gallery__single">
                        <Popup 
                        trigger=
                        {<img
                            className="gallery__photo" 
                            alt="Pokój" 
                            src={require("../images/pokoj.jpg")}
                        ></img>} 
                        position="center center">
                        <Room/>
                        </Popup>
                    </div>
                    <span>Pokój 2-osobowy</span>
                    </div>
                    
                    <div className="wrapper">
                    <div className="gallery__single">
                    <Popup 
                        trigger=
                        {<img
                            className="gallery__photo" 
                            alt="Studio" 
                            src={require("../images/studio.jpg")}
                        ></img>} 
                        position="center center">
                        <Studio/>
                        </Popup>
                    </div>
                    <span>APARTAMENT I STUDIO</span>
                    </div>
                    
                    <div className="wrapper">
                    <div className="gallery__single">
                    <Popup 
                        trigger=
                        {<img
                            className="gallery__photo" 
                            alt="Domek" 
                            src={require("../images/letniskowy.jpg")}
                        ></img>} 
                        position="center center">
                        <Cabin/>
                        </Popup>
                    </div>
                    <span>domek letniskowy</span>
                    </div>
                
                    <div className="wrapper">
                    <div className="gallery__single">
                        <Popup
                        trigger=
                        {<img
                            className="gallery__photo" 
                            alt="Okolica" 
                            src={require("../images/okolica1.jpg")}
                        ></img>} 
                        position="absolute">
                        <Bialogora/>
                        </Popup>
                    </div>
                    <span>okolica</span>
                    </div>
                    <div id="popup-root"></div>
                </div>
            </section>
            <Footer/>
        </>
    )
}