import ImageGallery from "react-image-gallery";
// import stylesheet if you're not already using CSS @import
import "react-image-gallery/styles/css/image-gallery.css";

export default function Studio() {
 const images = [
    {
      original: require("../../images/studio.jpg"),
      thumbnail: require("../../images/studio.jpg"),
      description: "Sypialnia"
    },
    {
        original: require("../../images/studio1.jpg"),
        thumbnail: require("../../images/studio1.jpg"),
        description: "Apartament"
      },
      {
        original: require("../../images/studio2.jpg"),
        thumbnail: require("../../images/studio2.jpg"),
        description: "Apartament"
      },
      {
        original: require("../../images/studio3.jpg"),
        thumbnail: require("../../images/studio3.jpg"),
        description: "Apartament"
      },
      {
        original: require("../../images/studio4.jpg"),
        thumbnail: require("../../images/studio4.jpg"),
        description: "Kuchnia"
      },
      {
        original: require("../../images/studio5.jpg"),
        thumbnail: require("../../images/studio5.jpg"),
        description: "Łazienka"
      },
      {
        original: require("../../images/studio6.jpg"),
        thumbnail: require("../../images/studio6.jpg"),
        description: "Pokój typu studio"
      },
      {
        original: require("../../images/studio10.jpg"),
        thumbnail: require("../../images/studio10.jpg"),
        description: "Apartament"
      }
  ];

  return (
    <ImageGallery items={images} />
  );
}