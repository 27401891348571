export default function Content() {
    return (
      <section className="allContent">
        <section className="statistics">
  <div className="container">
    <h1 className="title">Pokoje Gościnne "Arka"</h1>
    <div className="line"></div>
    <div className="statistics__container">
      <div className="statistics__single">
        <div className="statistics__circle">
          <img src={require("../../images/beach.png")} alt="person" className="statistics__img"/>
        </div>
        <div className="statistics__number">Zadowoleni goście</div>
        <div className="statistics__description">Bliskość plaży oraz atrakcji w miasteczku to ogromny atut naszych pokoi!</div>
      </div>

      <div className="statistics__single">
        <div className="statistics__circle">
          <img src={require("../../images/family.png")} alt="person" className="statistics__img"/>
        </div>
        <div className="statistics__number">Rodzinna atmosfera</div>
        <div className="statistics__description">Nasz ośrodek słynie z przyjaznej i ciepłej atmosfery - idealnej dla rodzin z dziećmi!</div>
      </div>

      <div className="statistics__single">
        <div className="statistics__circle">
          <img src={require("../../images/money.png")} alt="person" className="statistics__img"/>
        </div>
        <div className="statistics__number">Konkurencyjne ceny</div>
        <div className="statistics__description">W ośrodku oferujemy różne pokoje - na pewno każdy znajdzie coś dla siebie!</div>
      </div>
    </div>
  </div>
</section>
<section className="about">
  <h2 className="about__title">O nas</h2>
  <div className="about__container">
    <div className="about__left">
      <h3 className="about__miniTitle">Kilka słów o Białogórze</h3>
      <div className="about__text">
        Białogóra jest miejscowością letniskową położoną 
        na wybrzeżu Słowińskim. Miejscowość ta słynie przede 
        wszystkim z: "otwartego", czystego morza, szerokiej plaży, pięknych sosnowych lasów 
        oraz malowniczych wydm. Do plaży prowadzi kilka ścieżek 
        leśnych oraz asfaltowa aleja spacerowa ciągnąca się wśród 
        zielonych drzew i krzewów. Obszary leśne otaczające 
        miejscowość są terenami o urozmaiconej rzeźbie. Są to 
        zarówno obszary równinne, sprzyjające spacerom czy jeździe 
        rowerowej oraz pagórki i wzgórza, których pokonanie wymaga 
        od wczasowiczów większeg wysiłku. Znajduje 
        się tu <span>rezerwat florystyczny, stadnina koni oraz pole mini-golfa.</span> 
        W pobliżu znajdują się jeziora: <span>Żarnowieckie oraz Choczewskie.</span><br/>
        <br/>Białogóra, a w szczególności nasz ośrodek, to wymarzone 
        miejsce na udane wczasy nad morzem!<br/>
        </div>
        <h3 className="about__miniTitle">Ośrodek "Arka"</h3>
      <div className="about__text">
        Dom wypoczynkowy "Arka" mieści się na ogrodzonym terenie przy ulicy Lubiatowskiej. 
        Jest oddalony od przepięknej plaży o jedyne 20 minut spacerem. 
        Można do niej dotrzeć zarówno przez las, jak i urokliwym deptakiem. Dla turystów udostępnione 
        są także riksze oraz wóz konny. Ośrodek "Arka" oferuje do Państwa 
        dyspozycji w pełni wyposażone pokoje dwu- lub trzyosobowe, apartament 4-osobowy 
        oraz domek letniskowy z prywatną łazienką.
        Poza tym, na terenie ośrodka znajduje się także 
        prywatny parking dla naszych gości.
      </div>
    </div>
    <div className="about__right">
    <img className="about__img" src={require("../../images/osrodek.jpg")} alt="Ośrodek"></img>
    </div>
  </div>
</section>
</section>
    )
};