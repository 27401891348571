export default function Banner() {
    return (
        <section className="opening">
            <div className="opening__image">
                <div className="animation">
                    <h1 className="two">Białogóra "Arka"</h1>
                </div>
            </div>
        </section>
    )
}