import './App.css';
import { HashRouter, Routes, Route } from "react-router-dom";
import Main from './pages/Main';
import Prices from './pages/Prices';
import Rooms from './pages/Rooms';
import Trivia from './pages/Trivia';
import Contact from './pages/Contact';


function App() {
  return (
    <div className="App">
      <HashRouter>
                <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="/cennik" element={<Prices />} />
                    <Route path="/pokoje"  element={<Rooms />} />
                    <Route path="/kontakt"  element={<Contact />} />
                    <Route path="/ciekawostki" element={<Trivia />} />
                </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
