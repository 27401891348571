import Footer from "./components/Footer";
import Header from "./components/Header";

export default function Trivia() {
    return (
        <>
            <Header />
            <div className="trivia">
                <h2 className="trivia__title">Historia Białogóry</h2>
                <div className="trivia__container">
                    <div className="trivia__left">
                        <h3 className="trivia__miniTitle">Trochę historii...</h3>
                        <div className="trivia__text">Białogóra to uroczo położona miejscowość w 
                        Nadmorskim Parku Krajobrazowym pomiędzy Łebą a Karwią. 
                        Nazwa "Białogóra" została użyta w 1414 roku w krzyżackim 
                        przekazie źródłowym jako "Weissenberg" (Biało góra). 
                        Nazwa polska użyta była w inwentarzu dóbr i dochodów 
                        biskupstwa włocławskiego w 1534 roku. Miejscowość 
                        występowała również w piśmiennictwie pod innymi nazwami: 
                        Białagura (1659), Biała Góra (1772), Wittenberg (1912). 
                        Ostateczną, obecną jej nazwę ustalono w 1951 roku. 
                        W latach sześćdziesiątych powstają pierwsze ośrodki wypoczynkowe. 
                        W latach 70-tych i 80-tych ulega rozbudowie infrastruktura, od 
                        początku lat dziewięćdziesiątych nasila się rozbudowa pod kątem 
                        turystycznym: kwatery prywatne, pensjonaty.
                        </div>
                        <h3 className="trivia__miniTitle">Kilka faktów o Białogórze</h3>
                        <div className="trivia__text">Ta nieduża, ale prężnie rozwijająca się, miejscowość rozciąga 
                        się na skraju Wierzchucińskich Błot, oddzielona od plaży 
                        800-metrowym pasem lasu sosnowego. Przyjeżdżając tu, odkrywamy 
                        dzikie, czyste plaże, nie będące jednocześnie pozbawione odrobiny 
                        cywilizacji. Znakiem rozpoznawczym Białogóry jest górujący nad 
                        domami kościół z wieżą przykrytą jaskrawoczerwonym hełmem. 
                        Kościół otaczają nowe wille, przeważnie tynkowane na biało, 
                        budowane na dwa sposoby: albo w kubik, czyli z płaskim dachem, 
                        albo w trójkącik, czyli z dachem spadzistym. Być może z powodu 
                        zieleni w tle wioska sprawia przyjemne wrażenie. Największą atrakcją 
                        miejscowości jest oczywiście szeroka piaszczysta plaża położona nad otwartym morzem.
                        </div>
                        <h3 className="trivia__miniTitle">Przyroda i atrakcje</h3>
                        <div className="trivia__text">Dalej ciągnie się pasmo dość okazałych wydm. W odległości 2,5km 
                        na północny wschód od wsi znajduje się najwyższa zalesiona wydma, 
                        od której wzięło nazwę letnisko. Wokół ciągną się sosnowe lasy, 
                        gdzie można spacerować, zbierać grzyby lub jagody. Atrakcję stanowi 
                        też pobliska stadnina z zagrodami. W Białogórze działa komunikacja 
                        miejska na trasie centrum - plaża. W tej roli występują wozy zaprzężone 
                        w konie. Niedaleko od morza położony jest rezerwat Białogóra. Występuje 
                        tu wiele rzadkich gatunków roślin typowych dla flory atlantyckiej: 
                        woskownica europejska, wrzosiec bagienny, brzeżyca jednokwiatowa. 
                        Jednym słowem raj dla przyrodników i turystów.
                        </div>
                    </div>
                    <div className="trivia__right">
                        <img className="trivia__img" src={require("../images/plaza.jpg")} alt="Białogóra"></img>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}