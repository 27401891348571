import Banner from "./components/Banner";
import Content from "./components/Content";
import Footer from "./components/Footer";
import Header from "./components/Header";
import "./general.scss";

export default function Main() {
    return (
        <>
        <Header/>
        <Banner/>
        <Content/>
        <Footer/>        
        </>
    )
}
