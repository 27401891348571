import ImageGallery from "react-image-gallery";
// import stylesheet if you're not already using CSS @import
import "react-image-gallery/styles/css/image-gallery.css";

export default function Room() {
 const images = [
    {
      original: require("../../images/pokoj.jpg"),
      thumbnail: require("../../images/pokoj.jpg"),
      description: "Pokój 2-osobowy"
    },
    {
        original: require("../../images/pokoj1.jpg"),
        thumbnail: require("../../images/pokoj1.jpg"),
        description: "Pokój 2-osobowy"
      },
      {
        original: require("../../images/studio5.jpg"),
        thumbnail: require("../../images/studio5.jpg"),
        description: "Łazienka"
      },
      {
        original: require("../../images/pokoj3.jpg"),
        thumbnail: require("../../images/pokoj3.jpg"),
        description: "Pokój 2-osobowy"
      }
  ];

  return (
    <ImageGallery items={images} />
  );
}