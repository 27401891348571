import { Link } from "react-router-dom";
import "./mini_styles.scss";
import {useEffect, useRef} from "react"

export default function Header() {
let mybutton = document.getElementById("myBtn");
window.onscroll = function() {scrollFunction()};
function scrollFunction() {
    let mybutton = {};
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    let mybutton = {display:"block"};
  } else {
    let mybutton = {display:"block"};
  }
  return mybutton;
}
function topFunction() {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

  const newRef = useRef(null);
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  const handleOutsideClick = (e) => {
    if (newRef.current && !newRef.current.contains(e.target)) {
      document.getElementById("menu__btn").checked = false;
    }
  };

    return (
        <header className="header">
            <div className="header__container">
                <div className="logo">
                    <Link to="/">Arka</Link>
                </div>
                <div className="arrowContainer">
                    <p>MENU</p>
                    <p><i class="arrow right"></i></p>
                </div>
                <nav className="header__nav" ref={newRef}>
                    <input type="checkbox" className="menu__btn" id="menu__btn"/>
                        <label for="menu__btn" className="menu__toggle">
                            <span></span>
                            <span></span>
                            <span></span>
                        </label>
                    <ul className="header__list">
                        <li className="list__element" onClick={topFunction} style={mybutton}><Link to="/" className="list__link">Strona Główna</Link></li>
                        <li className="list__element" onClick={topFunction} style={mybutton}><Link to="/pokoje" className="list__link">Galeria</Link></li>
                        <li className="list__element" onClick={topFunction} style={mybutton}><Link to="/cennik" className="list__link">Cennik</Link></li>
                        <li className="list__element" onClick={topFunction} style={mybutton}><Link to="/kontakt" className="list__link">Kontakt</Link></li>
                        <li className="list__element" onClick={topFunction} style={mybutton}><Link to="/ciekawostki" className="list__link">Ciekawostki</Link></li>
                    </ul>
                </nav>
            </div>
        </header>
    )
}