import { Link } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";

export default function Prices() {
    return (
        <>
            <Header/>
                <section className="pricesSection">
                    <div className="additionalInfo">
                        Istnieje możliwość wynajmu całorocznego - ceny ustalamy indywidualnie!
                        Zapraszamy do kontaktu!
                    </div>
                    <div className="prices">
                    <h2 className="prices__title">Cennik</h2>
                    
                        <div className="priceCards">
                    <Link to="/pokoje">
                            <div className="priceCard priceCard__one">
                                <div className="priceCard__title">
                                    <h3>pokój<br/>dwuosobowy</h3>
                                </div>
                                <div className="priceCard__image">
                                    <img className="priceCard__photo" src={require("../images/pokoj.jpg")} alt="pokoj"></img>
                                </div>
                                <div className="priceCard__content">
                                    <div className="priceCard__month">MAJ, CZERWIEC, WRZESIEŃ</div>
                                    <div className="priceCard__price">150zł/dobę</div>
                                    <div className="priceCard__month">LIPIEC - SIERPIEŃ</div>
                                    <div className="priceCard__price">160zł/dobę</div>
                                </div>
                                <div className="priceCard__btn priceCard__btnOne">
                                    <Link to="/pokoje">Zobacz więcej zdjęć!</Link>
                                </div>
                            </div>
                        </Link>
                        <Link to="/pokoje">
                            <div className="priceCard priceCard__two">
                                <div className="priceCard__title">
                                    <h3>apartament i<br/>studio</h3>
                                </div>
                                <div className="priceCard__image">
                                    <img className="priceCard__photo" src={require("../images/studio.jpg")} alt="pokoj"></img>
                                </div>
                                <div className="priceCard__content">
                                    <div className="priceCard__month">MAJ, CZERWIEC, WRZESIEŃ</div>
                                    <div className="priceCard__price">280zł/dobę</div>
                                    <div className="priceCard__month">LIPIEC - SIERPIEŃ</div>
                                    <div className="priceCard__price">290zł/dobę</div>
                                </div>
                                <div className="priceCard__btn priceCard__btnTwo">
                                    <Link to="/pokoje">Zobacz więcej zdjęć!</Link>
                                </div>
                            </div>
                            </Link>
                            <Link to="/pokoje">
                            <div className="priceCard priceCard__three">
                                <div className="priceCard__title">
                                    <h3>domek<br/>letniskowy</h3>
                                </div>
                                <div className="priceCard__image">
                                    <img className="priceCard__photo" src={require("../images/letniskowy7.JPG")} alt="pokoj"></img>
                                </div>
                                <div className="priceCard__content">
                                    <div className="priceCard__month">MAJ, CZERWIEC, WRZESIEŃ</div>
                                    <div className="priceCard__price">180zł/dobę</div>
                                    <div className="priceCard__month">LIPIEC - SIERPIEŃ</div>
                                    <div className="priceCard__price">190zł/dobę</div>
                                </div>
                                <div className="priceCard__btn priceCard__btnThree">
                                    <Link to="/pokoje">Zobacz więcej zdjęć!</Link>
                                </div>
                            </div>
                            </Link>
                        </div>
                    </div>
                </section>
                <Footer/>
        </>
    )
}